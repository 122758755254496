// MODULES

// STYLESHEETS

// PAGES

// COMPONENTS

// UTILS
import { getSession } from "./cookies";

// CLASSES

// MAIN
const db_address = process.env.REACT_APP_DB_ADDRESS
if (!db_address) {
    console.error("DB_ADDRESS is not available/found, please check configuration and try again");
}

export async function LoginUser(email, password) {

    if (email === undefined || password === undefined){
        let errorMessage = {"message": "Email or Password is undefined"}
        return  errorMessage; // idk wtf to do here for now
    }

    const data = {"email": email, "password": password};

    try{
        const response = await fetch(`http://${db_address}/web/login/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        responseData.ok = response.ok;
        responseData.status = response.status;

        return responseData;
        
    } catch (error) {
        console.error("Error:", error);
        return {"message": error};
    }
}

export async function RegisterUser() {
    return "kek"
}

export async function ValidateUser() {

    const session_id = getSession();

    if (session_id) {
        console.log("ValidateUser: session_id found!");

        if (session_id === "undefined" || session_id === undefined || session_id === "" || session_id === null){
            console.log("ValidateUser: undefined, null, or empty in some fashion");
            return {"validated": false}
        }

        const data = {"session": session_id};

        try{
            const response = await fetch(`http://${db_address}/web/validate/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                console.log("ValidateUser: response is NOT OK");
                if (response.status === 404 || response.status === 401){
                    console.log("ValidateUser: status code -> ", response.status);
                    return {"validated": false}
                }
                // edge case incase server gives a 500
                console.log("ValidateUser: somehow hitting 500");
                return {"validated": false}
            }
            console.log("ValidateUser: SUCCESS");
            return {"validated": true}
        } catch (error) {
            console.error(error);
            return {"validated": false}
        }
    }

    return {"validated": false}
}