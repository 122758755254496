// MODULES
import React from 'react';

// STYLESHEETS

// PAGES

// COMPONENTS

// UTILS

// CLASSES

// MAIN
const Layout = ({ children }) => {
    return(
        <div>
            {children}
        </div>
    );
};

export default Layout;