// MODULES
import {Routes, Route} from 'react-router-dom';

// STYLESHEETS
import './App.css';

// PAGES
import Landing from "./pages/landing/landing.js";
import Login from "./pages/login/login.js";
import Register from "./pages/register/register.js";
import Dashboard from "./pages/dashboard/dashboard.js";

// COMPONENTS

// UTILS
import { UserProvider } from './contexts/userContext.js';
import {SessionLocked, SessionRequired} from './utils/privateRoutes.js';
import Layout from './utils/layout.js';

// CLASSES

// MAIN
function App() {
  return (
    <div className="App">
      <UserProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<Landing />} />
            {/* <Route element={<SessionLocked />} >
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Route>
            <Route element={<SessionRequired/>}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route> */}
          </Routes>
        </Layout>
      </UserProvider>
    </div>
  );
}

export default App;
