// MODULES

// STYLESHEETS

// PAGES

// COMPONENTS

// UTILS

// CLASSES

// MAIN
export const getSession = () => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    const cookie = cookies.find(cookie => cookie.startsWith("flexerp_session="));
    return cookie ? cookie.split('=')[1] : null;
};

export const removeSession = (session_id) => {
    document.cookie = `flexerp_session=${session_id};expires=Thu, 01 Jan 1970 00:00:00 GMT`;
};

export const addSession = (session_id, startTime) => {
    const expirationTime = new Date(startTime)
    expirationTime.setSeconds(expirationTime.getSeconds() + 21600)// 6 hours
    const expires = expirationTime.toUTCString();
    const cookieString = `flexerp_session=${session_id};expires=${expires}`;
    document.cookie = cookieString;
}; 