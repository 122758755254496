class UserStructure {
    constructor(id, uuid, email, companyId, networkId, firstName, lastName, lastLogin){
        this.id = id;
        this.uuid = uuid;
        this.email = email;
        this.companyId = companyId;
        this.networkId = networkId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.lastLogin = lastLogin;
    }
}

export default UserStructure;