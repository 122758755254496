//MODULES
import { createContext, useContext, useState, useEffect } from 'react';

// STYLESHEETS

// PAGES

// COMPONENTS

// UTILS
import { ValidateUser } from '../utils/dbCalls';
import { addSession, removeSession, getSession } from '../utils/cookies';

// CLASSES
import UserStructure from '../utils/classes/UserStructure';
import SessionStructure from '../utils/classes/SessionStructure';

// MAIN
const unknownUser = new UserStructure(-1, "", "", -1, -1, "", "", "");

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(unknownUser);

    useEffect(() => {
        // TODO: logic to read local storage data about session and if it should still be valid or not
        console.log("Ignore me, im just a filler instruction for an empty useEffect()");
    }, []);

    // newUserData -> Expects a UserStructure Object
    // newSessionData -> Expects a SessionStructure Object
    const SetUserData = (newUserData, newSessionData) => {
        console.log(newSessionData);
        if (newUserData && newSessionData){
            setUser(newUserData);
            addSession(newSessionData.session_id, newSessionData.last_access_time);
        } else {
            console.error("UNABLE TO SET USER DATA IN USER CONTEXT; MISSING INFORMATION.");
        }
    };

    // called only when the user manually logs off
    const LogOutUser = () => {
        const session_id = getSession();
        removeSession(session_id);
    }

    const IsUserLoggedIn = async () => {
        const { user } = useUser();
        if (user.id === -1 || user.uuid === ""){
            console.log("User's id/uuid is not properly setup in context; Denying Logged In Check");
            return false;
        } else {
            //validate session in table
            const result = await ValidateUser(user.uuid);
            if (result) {
                return result.validated;
            }
            return false;
        }
    }

    return(
        <UserContext.Provider value={{ user, SetUserData, IsUserLoggedIn, UserStructure }}>
            {children}
        </UserContext.Provider>
    );

};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        console.error('useUser must be used within a CreditsProvider');
    }
    console.log("useUser hook used, current context:", context);
    return context;
}