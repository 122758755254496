// MODULES
import React, { useEffect } from 'react';

// STYLESHEETS
import './landing.module.css';

// PAGES

// COMPONENTS

// UTILS

// CLASSES

// MAIN

const Landing = () => {

  // useEffect(() => {
  //   const canvas = document.getElementById('canvas');
  //   const ctx = canvas.getContext('2d');

  //   let w = canvas.width = window.innerWidth;
  //   let h = canvas.height = window.innerHeight;
  //   let particles = [];

  //   function resize() {
  //     w = canvas.width = window.innerWidth;
  //     h = canvas.height = window.innerHeight;
  //   }

  //   function tick() {
  //     requestAnimationFrame(tick);
  //     ctx.clearRect(0, 0, w, h);

  //     particles.forEach(p => {
  //       p.update();
  //       p.draw();
  //     });

  //     if (particles.length < 100) {
  //       particles.push(new Particle());
  //     }
  //   }

  //   class Particle {
  //     constructor() {
  //       this.x = Math.random() * w;
  //       this.y = Math.random() * h;
  //       this.speed = 0.1 + Math.random();
  //       this.size = Math.random() * 5;
  //       this.color = 'rgba(78, 234, 246, 0.5)';
  //     }

  //     update() {
  //       this.y -= this.speed;
  //       if (this.y < 0) {
  //         this.y = h;
  //         this.x = Math.random() * w;
  //       }
  //     }

  //     draw() {
  //       ctx.fillStyle = this.color;
  //       ctx.beginPath();
  //       ctx.arc(this.x, this.y, this.size, 0, 2 * Math.PI);
  //       ctx.fill();
  //     }
  //   }

  //   window.addEventListener('resize', resize);
  //   tick(); // Start animation

  //   return () => window.removeEventListener('resize', resize);
  // }, []);

  return (
    <div className="landing">
  {/* <canvas id="canvas"></canvas> */}
  <div className="content">
    {/* <div className="logo-box">
      <img
        src="https://i.ibb.co/S5TNfwV/standalone-logo.png"
        alt="Flex Solutions Logo"
        className="logo"
      />
    </div> */}
    <h1 className="company-name">Flex Solutions</h1>
    <h3>Coming Soon</h3>
    {/* <p className="description">
      Veteran-owned software development company specializing in delivering
      versatile and innovative software solutions for businesses.
    </p>
    <h2 className="slogan">"Adaptable Solutions, Exceptional Results"</h2>
    <button className="cta-button">
      Learn More →
    </button> */}
  </div>
</div>
  );
};

export default Landing;
